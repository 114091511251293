export default [
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
  },

  // *===============================================---*
  // *--------- AUDIT ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/system/audit',
    name: 'apps-audit-audit-list',
    component: () => import('@/views/apps/audit/audit-list/AuditList.vue'),

    meta: { permission: 'audit.audit.list', pageTitle: 'View Audit Log' },
  },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/users',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),

    meta: { permission: 'user.list', pageTitle: 'Users List' },
  },
  {
    path: '/users/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),

    meta: { permission: 'user.get', pageTitle: 'View User' },
  },
  {
    path: '/users/:id/edit',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),

    meta: { permission: 'user.update', pageTitle: 'Edit User' },
  },

  // *===============================================---*
  // *--------- STUDENT ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/students',
    name: 'apps-students-list',
    component: () => import('@/views/apps/student/students-list/StudentsList'),

    meta: { permission: 'student.list', pageTitle: 'Students List' },
  },
  {
    path: '/students/blacklist',
    name: 'apps-students-blacklist',
    component: () => import('@/views/apps/student/students-blacklist/StudentsBlacklist'),

    meta: { permission: 'student.blacklist.list', pageTitle: 'Student Blacklist' },
  },
  {
    path: '/students/:id',
    name: 'apps-students-view',
    component: () => import('@/views/apps/student/students-view/StudentsView.vue'),

    meta: { permission: 'student.get', pageTitle: 'View Student' },
  },
  {
    path: '/students/:id/edit',
    name: 'apps-students-edit',
    component: () => import('@/views/apps/student/students-edit/StudentsEdit'),

    meta: { permission: 'student.update', pageTitle: 'Edit Student' },
  },
  // {
  //   path: '/users/:id/edit',
  //   name: 'apps-users-edit',
  //   component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  //
  //   meta: {
  //     resource: 'user',
  //     action: 'write',
  //   },
  // },

  // Invoice
  {
    path: '/apps/invoice/list',
    name: 'apps-invoice-list',
    component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
  },
  {
    path: '/apps/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
  },
  {
    path: '/apps/invoice/add/',
    name: 'apps-invoice-add',
    component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
  },
  {
    path: '/apps/invoice/edit/:id',
    name: 'apps-invoice-edit',
    component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
  },
  // Job
  {
    path: '/jobs',
    name: 'apps-job-list',
    component: () => import('@/views/apps/job/jobs-list/JobsList'),

    meta: { permission: 'export.list', pageTitle: 'Jobs List' },
  },
  /**
   * ACCOUNTING
   */
  {
    path: '/accounting/receivable/reconcile',
    name: 'apps-accounting-receivable-reconcile',
    component: () => import('@/views/apps/accounting/receivable/reconcile/Reconcile'),

    meta: { permission: 'accounting.receivable.reconcile', pageTitle: 'Reconciliation Wizard' },
  },
  {
    path: '/accounting/receivable/accounts',
    name: 'apps-accounting-receivable-account-list',
    component: () => import('@/views/apps/accounting/receivable/account-list/AccountList'),

    meta: { permission: 'accounting.receivable.account.list', pageTitle: 'Accounts List' },
  },
  {
    path: '/accounting/receivable/accounts/:id',
    name: 'apps-accounting-receivable-account-view',
    component: () => import('@/views/apps/accounting/receivable/account-view/AccountView'),

    meta: { permission: 'accounting.receivable.account.get', pageTitle: 'View Account' },
  },
  {
    path: '/accounting/receivable/accounts/:id/edit',
    name: 'apps-accounting-receivable-account-edit',
    component: () => import('@/views/apps/accounting/receivable/account-edit/AccountEdit'),

    meta: { permission: 'accounting.receivable.account.update', pageTitle: 'Edit Account' },
  },
  {
    path: '/accounting/receivable/templates',
    name: 'apps-accounting-receivable-template-list',
    component: () => import('@/views/apps/accounting/receivable/template-list/TemplateList'),

    meta: { permission: 'accounting.receivable.template.list', pageTitle: 'Templates List' },
  },
  {
    path: '/accounting/receivable/templates/:id/edit',
    name: 'apps-accounting-receivable-template-edit',
    component: () => import('@/views/apps/accounting/receivable/template-edit/TemplateEdit'),

    meta: { permission: 'accounting.receivable.template.update', pageTitle: 'Edit Template ' },
  },
  {
    path: '/accounting/receivable/cohorts',
    name: 'apps-accounting-receivable-cohort-list',
    component: () => import('@/views/apps/accounting/receivable/cohort-list/CohortList'),

    meta: { permission: 'accounting.receivable.cohort.list', pageTitle: 'Cohorts List' },
  },
  {
    path: '/accounting/receivable/cohorts/sheet',
    name: 'apps-accounting-receivable-cohort-import',
    component: () => import('@/views/apps/accounting/receivable/cohort-import/CohortImport'),

    meta: { permission: 'ui.accounting.receivable.cohort.sheet' },
  },
  {
    path: '/accounting/receivable/cohorts/:id',
    name: 'apps-accounting-receivable-cohort-view',
    component: () => import('@/views/apps/accounting/receivable/cohort-view/CohortsView'),

    meta: { permission: 'accounting.receivable.cohort.get', pageTitle: 'View Cohort' },
  },
  {
    path: '/accounting/receivable/cohorts/:id/edit',
    name: 'apps-accounting-receivable-cohort-edit',
    component: () => import('@/views/apps/accounting/receivable/cohort-edit/CohortsEdit'),

    meta: { permission: 'accounting.receivable.cohort.update', pageTitle: 'Edit Cohort' },
  },
  // {
  //   path: '/jobs/:id',
  //   name: 'apps-invoice-preview',
  //   component: () => import('@/views/apps/job/invoice-preview/InvoicePreview.vue'),
  // },
  // Report
  {
    path: '/reports',
    name: 'apps-report-list',
    component: () => import('@/views/apps/report/report-list/ReportList.vue'),

    meta: { permission: 'report.list', pageTitle: 'Financial Reports List' },
  },
  {
    path: '/reports/import',
    name: 'apps-report-import',
    component: () => import('@/views/apps/report/report-import/ReportImport'),

    meta: { permission: 'report.import', pageTitle: 'Import Financial Reports' },
  },
  {
    path: '/reports/create',
    name: 'apps-report-add',
    component: () => import('@/views/apps/report/report-add/ReportAdd.vue'),

    meta: { permission: 'report.create', pageTitle: 'Create Financial Report' },
  },
  {
    path: '/reports/:id/preview',
    name: 'apps-report-preview',
    component: () => import('@/views/apps/report/report-preview/ReportPreview.vue'),

    meta: { permission: 'report.render', pageTitle: 'Preview Financial Report' },
  },
  {
    path: '/reports/:id/edit',
    name: 'apps-report-edit',
    component: () => import('@/views/apps/report/report-edit/ReportEdit.vue'),

    meta: { permission: 'report.update', pageTitle: 'Edit Financial Report' },
  },

  //////////// PLACEMENT SYSTEM

  {
    path: '/placement/monday/templates',
    name: 'apps-placement-monday-template-list',
    component: () => import('@/views/apps/placement/monday-template-list/MondayTemplateList'),

    meta: { permission: 'integrations.monday.template.list', pageTitle: 'Templates LIst' },
  },

  {
    path: '/placement/cohorts',
    name: 'apps-placement-cohort-list',
    component: () => import('@/views/apps/placement/cohort-list/CohortList'),

    meta: { permission: 'placement.cohort.list', pageTitle: 'Cohorts List' },
  },

  {
    path: '/placement/cohorts/:id',
    name: 'apps-placement-cohort-view',
    component: () => import('@/views/apps/placement/cohort-view/CohortsView'),

    meta: { permission: 'placement.cohort.get', pageTitle: 'View Cohort' },
  },
  {
    path: '/placement/cohorts/:id/edit',
    name: 'apps-placement-cohort-edit',
    component: () => import('@/views/apps/placement/cohort-edit/CohortsEdit'),

    meta: { permission: 'placement.cohort.update', pageTitle: 'Edit Cohort' },
  },

  {
    path: '/placement/profiles/:id',
    name: 'apps-placement-profile-view',
    component: () => import('@/views/apps/placement/profile-view/ProfilesView'),

    meta: { permission: 'placement.profile.get', pageTitle: 'View Profile' },
  },
  {
    path: '/placement/profiles/:id/edit',
    name: 'apps-placement-profile-edit',
    component: () => import('@/views/apps/placement/profile-edit/ProfilesEdit'),

    meta: { permission: 'placement.profile.list', pageTitle: 'Edit Profile' },
  },

  //////////// ROSTER SYSTEM

  {
    path: '/roster/profiles/search',
    name: 'apps-roster-search-profile',
    component: () => import('@/views/apps/roster/search-profile/ProfileList'),

    meta: { permission: 'roster.profile.list', pageTitle: 'Certificate Search' },
  },
  {
    path: '/roster/profiles',
    name: 'apps-roster-profile-list',
    component: () => import('@/views/apps/roster/profile-list/ProfileList'),

    meta: { permission: 'roster.profile.list', pageTitle: 'Profiles List' },
  },
  {
    path: '/roster/profiles/:id',
    name: 'apps-roster-profile-view',
    component: () => import('@/views/apps/roster/profile-view/ProfilesView'),

    meta: { permission: 'roster.profile.get', pageTitle: 'View Profile' },
  },
  {
    path: '/roster/profiles/:id/edit',
    name: 'apps-roster-profile-edit',
    component: () => import('@/views/apps/roster/profile-edit/ProfilesEdit'),

    meta: { permission: 'roster.profile.update', pageTitle: 'Edit Profile' },
  },
  {
    path: '/roster/subjects',
    name: 'apps-roster-subject-list',
    component: () => import('@/views/apps/roster/subjects-list/SubjectList'),

    meta: { permission: 'roster.subject.list', pageTitle: 'Subjects List' },
  },
  {
    path: '/roster/subjects/:id',
    name: 'apps-roster-subject-view',
    component: () => import('@/views/apps/roster/subjects-view/SubjectsView'),

    meta: { permission: 'roster.subject.get', pageTitle: 'View Subject' },
  },
  {
    path: '/roster/subjects/:id/edit',
    name: 'apps-roster-subject-edit',
    component: () => import('@/views/apps/roster/subjects-edit/SubjectsEdit'),

    meta: { permission: 'roster.subject.update', pageTitle: 'Edit Subject' },
  },
  {
    path: '/roster/courses',
    name: 'apps-roster-course-list',
    component: () => import('@/views/apps/roster/courses-list/CourseList'),

    meta: { permission: 'roster.course.list', pageTitle: 'Courses List' },
  },
  {
    path: '/roster/courses/:id',
    name: 'apps-roster-course-view',
    component: () => import('@/views/apps/roster/courses-view/CoursesView'),

    meta: { permission: 'roster.course.get', pageTitle: 'View Course' },
  },
  {
    path: '/roster/courses/:id/edit',
    name: 'apps-roster-course-edit',
    component: () => import('@/views/apps/roster/courses-edit/CoursesEdit'),

    meta: { permission: 'roster.course.update', pageTitle: 'Edit Course' },
  },
  {
    path: '/roster/sessions',
    name: 'apps-roster-session-list',
    component: () => import('@/views/apps/roster/sessions-list/SessionList'),

    meta: { permission: 'roster.session.list', pageTitle: 'Sessions List' },
  },
  {
    path: '/roster/sessions/upcoming',
    name: 'apps-roster-session-upcoming-list',
    component: () => import('@/views/apps/roster/sessions-upcoming-list/SessionList'),

    meta: {
      permission: 'roster.session.upcoming.list',
      pageTitle: 'Upcoming Sessions',
      kind: 'Upcoming',
    },
  },
  {
    path: '/roster/sessions/live',
    name: 'apps-roster-session-live-list',
    component: () => import('@/views/apps/roster/sessions-upcoming-list/SessionList'),

    meta: {
      permission: 'roster.session.live.list',
      pageTitle: 'Live Sessions',
      kind: 'Live',
    },
  },
  {
    path: '/roster/sessions/builder',
    name: 'apps-roster-session-builder',
    component: () => import('@/views/apps/roster/sessions-builder/SessionsBuilder'),

    meta: { permission: 'roster.session.create', pageTitle: 'Session Builder' },
  },
  {
    path: '/roster/sessions/:id',
    name: 'apps-roster-session-view',
    component: () => import('@/views/apps/roster/sessions-view/SessionsView'),

    meta: { permission: 'roster.session.get', pageTitle: 'View Session' },
  },
  {
    path: '/roster/sessions/:id/edit',
    name: 'apps-roster-session-edit',
    component: () => import('@/views/apps/roster/sessions-edit/SessionsEdit'),

    meta: { permission: 'roster.session.update', pageTitle: 'Edit Session' },
  },
  {
    path: '/roster/pathways',
    name: 'apps-roster-pathway-list',
    component: () => import('@/views/apps/roster/pathways-list/PathwayList'),

    meta: { permission: 'roster.pathway.list', pageTitle: 'Pathways List' },
  },
  {
    path: '/roster/pathways/:id',
    name: 'apps-roster-pathway-view',
    component: () => import('@/views/apps/roster/pathways-view/PathwaysView'),

    meta: { permission: 'roster.pathway.get', pageTitle: 'View Pathway' },
  },
  {
    path: '/roster/pathways/:id/builder',
    name: 'apps-roster-pathway-builder',
    component: () => import('@/views/apps/roster/pathways-builder/PathwayInteractiveBuilder'),

    meta: { permission: 'roster.pathway.create', pageTitle: 'Create Session' },
  },
  {
    path: '/roster/pathways/:id/edit',
    name: 'apps-roster-pathway-edit',
    component: () => import('@/views/apps/roster/pathways-edit/PathwaysEdit'),

    meta: { permission: 'roster.pathway.update', pageTitle: 'Edit Pathway' },
  },

  {
    path: '/roster/calendars',
    name: 'apps-roster-calendar-list',
    component: () => import('@/views/apps/roster/calendars-list/CalendarList'),

    meta: { permission: 'roster.calendar.list', pageTitle: 'Calendars' },
  },
];
