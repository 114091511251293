import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchJobs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('jobs', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchJob(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`jobs/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addJob(ctx, jobData) {
      return new Promise((resolve, reject) => {
        axios
          .post('jobs', jobData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
